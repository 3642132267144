import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';


const PrimaryBtn = styled.button`
    border-radius: 50px;
    width: auto;
    padding: 1.1em 2.5em;
    font-size:15px;
    font-weight:300;
    font-family:'Montserrat';
    background-color: ${(props) => props.backgroundColor};
    color: ${(props) => props.color};
    border: 2px solid ${(props) => props.borderColor};
    cursor: pointer;

    &:hover{
        background-color:transparent;
        color: ${(props) => props.hoverColor};
        border: 2px solid ${(props) => props.hoverBorderColor};
        height:auto;
    }

    @media only screen and (min-width : 481px) and (max-width : 767px) {

        .primary-button{
            margin-top: 5%;
            margin-bottom: 5%;
            padding: 1.1em 1.5em !important;
        }
    }

    @media only screen and (min-width : 320px) and (max-width : 480px) {

        .primary-button{
            margin-top: 5%;
            margin-bottom: 5%;
            padding: 1.1em 1.5em !important;
        }
    }
`;

const PrimaryButton = ({
    style,
    backgroundColor,
    color,
    borderColor,
    hoverColor,
    hoverBorderColor,
    onClick,
    titleButton
}) => (
    <PrimaryBtn
        style={style}
        backgroundColor={backgroundColor}
        color={color}
        borderColor={borderColor}
        hoverColor={hoverColor}
        hoverBorderColor={hoverBorderColor}
        onClick={onClick}>{titleButton}</PrimaryBtn>
);

PrimaryButton.defaultProps = {
    style: {},
    onClick: () => {}
};

PrimaryButton.propTypes = {
    style: PropTypes.object,
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
    borderColor: PropTypes.string,
    hoverColor: PropTypes.string,
    hoverBorderColor: PropTypes.string,
    onClick: PropTypes.func,
    titleButton: PropTypes.string
};

export default React.memo(PrimaryButton);
